import React from 'react';
import SideNavBar from '../../../components/sidebar/SideNavBar';
import PostCard from './PostCard';




const PostDetailsPage: React.FC = () => {
    return ( 
        <div className='flex'>
            <SideNavBar/>
            <PostCard/>
        </div>
    )
}


export default PostDetailsPage;