import React, {ChangeEvent,FormEvent,useState,useEffect} from "react";
import {useParams,useNavigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import axiosInstance from "../../../axios/axiosSetup";


interface Recipe {
    _id: string;
    name: string;
    author: string;
    prepHours: string;
    prepMinutes: string;
    ingredients: string;
    instructions: string;
    image: string;
}

const EditRecipeForm:React.FC = () => {
    const {id} = useParams<{id:string}>();
    const navigate = useNavigate();

    const [formData,setFormData] = useState({
        name:"",
        author:"",
        prepHours:"",
        prepMinutes:"",
        ingredients:"",
        instructions:"",
    })

    const [userId,setUserId] = useState("");
    const [file,setFile] = useState<File | null>(null);
    const [previewUrl,setPreviewUrl] = useState<string | null>(null);

    
    useEffect(() => {
        const fetchRecipe = async () => {
            try {
                const response = await axiosInstance.get(`/recipe/${id}`);
                const { name, author, prepHours, prepMinutes, ingredients, instructions, image } = response.data;
                setFormData({
                    name,
                    author,
                    prepHours,
                    prepMinutes,
                    ingredients,
                    instructions,
                });
                if (image) {
                    setPreviewUrl(image);
                }
            } catch (error) {
                console.error('Failed to fetch recipe details:', error);
            }
        };

        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');

                if (!token) {
                    throw new Error('No token found');
                }

                const decoded: any = jwtDecode(token);
                const userId = decoded.id;
                setUserId(userId);

                const response = await axiosInstance.get(`/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(response.data);

            } catch (error) {
                console.error('Failed to fetch user information:', error);
            }
        };

        fetchUserInfo();
        fetchRecipe();
    }, [id]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);

            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result as string);
            };
            fileReader.readAsDataURL(selectedFile);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData();
        data.append('name', formData.name);
        data.append('author', formData.author);
        data.append('prepHours', formData.prepHours);
        data.append('prepMinutes', formData.prepMinutes);
        data.append('ingredients', formData.ingredients);
        data.append('instructions', formData.instructions);
        data.append('userId', userId);
        if (file) {
            data.append('file', file);
        }

        try {
            const response = await axiosInstance.put(`/recipe/${id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Recipe updated:', response.data);
            navigate('/recipesDashboard');
        } catch (err) {
            console.error('Error with updating recipe:', err);
        }
    };

    return ( 
        <section className="flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80">
        <div className="bg-white shadow-2xl rounded-xl px-8 pt-6 pb-8 mb-4 w-full max-w-4xl">
            <h1 className="text-2xl font-bold text-spring-green-900 text-center mb-4">Edit Recipe</h1>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-6">
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Recipe Name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            required
                            value={formData.name}
                            onChange={handleInputChange}
                            placeholder="Enter recipe name"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Author</label>
                        <input
                            id="author"
                            name="author"
                            type="text"
                            required
                            value={formData.author}
                            onChange={handleInputChange}
                            placeholder="Enter author name"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Prep Time</label>
                        <div className="flex space-x-2">
                            <select
                                name="prepHours"
                                value={formData.prepHours}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                                <option disabled value="">
                                    Hours
                                </option>
                                {Array.from(Array(24), (_, i) => (
                                    <option key={i} value={i}>
                                        {i}
                                    </option>
                                ))}
                            </select>
                            <select
                                name="prepMinutes"
                                value={formData.prepMinutes}
                                onChange={handleInputChange}
                                className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                                <option disabled value="">
                                    Minutes
                                </option>
                                {Array.from(Array(60), (_, i) => (
                                    <option key={i} value={i}>
                                        {i}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Ingredients</label>
                        <textarea
                            id="ingredients"
                            name="ingredients"
                            required
                            value={formData.ingredients}
                            onChange={handleInputChange}
                            placeholder="Enter ingredients"
                            rows={6}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                </div>
                <div className="space-y-6">
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">Instructions</label>
                        <textarea
                            id="instructions"
                            name="instructions"
                            required
                            value={formData.instructions}
                            onChange={handleInputChange}
                            placeholder="Enter instructions"
                            rows={6}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div>
                        <input
                            type="file"
                            name="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="hidden"
                            id="fileInput"
                        />
                        <label
                            htmlFor="fileInput"
                            className="flex items-center justify-center w-full bg-black text-white py-2 px-4 rounded cursor-pointer hover:bg-spring-green-900"
                        >
                            Upload Image
                        </label>
                        {previewUrl && (
                            <img
                                src={previewUrl}
                                alt="Image Preview"
                                className="mt-4 w-full h-48 object-cover rounded shadow"
                            />
                        )}
                    </div>
                </div>
                <div className="col-span-full flex justify-center">
                    <button
                        className="bg-black hover:bg-spring-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Update Recipe
                    </button>
                </div>
            </form>
        </div>
    </section>
    )

}


export default EditRecipeForm