import React, { FormEvent, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosSetup';
import { jwtDecode } from 'jwt-decode';
import PostCommentCard from './PostCommentCard';


interface Post {
    _id: string;
    postTitle: string;
    postAuthor: string;
    postDescription: string;
    postDate: string;
    postHashtags: string;
}

interface User {
    username: string;
}



const PostCard: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [userId, setUserId] = useState('');
    const [content, setContent] = useState('');
    const [user, setUser] = useState<User | null>(null);
    const [post, setPost] = useState<Post | null>(null);
    const [loading, setLoading] = useState(true);
    const [username, setUsername] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login'); // Redirect to login if no token found
            return;
        }

        const decoded: any = jwtDecode(token);
        setIsLoggedIn(!!token);
        setUserId(decoded.id);

        const fetchUserInfo = async () => {
            try {
                const response = await axiosInstance.get(`/users/${decoded.id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUser(response.data);
                setUsername(response.data.username);
            } catch (error) {
                console.error('Failed to fetch user information:', error);
            }
        };

        const fetchPost = async () => {
            try {
                const res = await axiosInstance.get(`/posts/${id}`);
                setPost(res.data);
            } catch (err) {
                console.error('Failed to fetch post details', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserInfo();
        fetchPost();
    }, [id, navigate]);

    if (loading) {
        return (
            <div className='flex items-center justify-center min-h-screen bg-gray-100'>
                <div className='text-2xl font-semibold text-gray-600'>Loading...</div>
            </div>
        );
    }

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await axiosInstance.post('/postComments/create', {
                postId: id,
                author: username,
                content,
                userId,
            });
            setContent('');
            window.location.reload();
        } catch (error) {
            console.error('Failed to post comment:', error);
        }
    };
    return (

        <section className='flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80'>
            <div className='  w-full max-w-4xl p-8'>
                <h1 className='text-center text-3xl font-bold mb-4'>{post?.postTitle}</h1>
                <div className='bg-gray-50  hover:scale-105 transition-transform duration-300 rounded-lg shadow-lg p-8 border hover:border-gray-700 mb-6'>
                    <p className='text-lg text-black mb-4'>{post?.postDescription}</p>
                    <div className='flex flex-col sm:flex-row sm:gap-5 mt-4 text-sm text-gray-600'>
                        <span>By: <span className='text-black'>{post?.postAuthor}</span></span>
                        <span className='text-black'>{post?.postDate}</span>
                        <span className='text-blue-500'>{post?.postHashtags}</span>
                    </div>
                </div>
                <div className='w-full  rounded-lg p-5 hover:scale-105 transition-transform duration-300 shadow-lg border hover:border-gray-700'>
                    <h2 className='text-2xl font-bold mb-4'>Add Comment</h2>
                    <form className='mb-6 bg-white rounded-lg p-4' onSubmit={handleSubmit}>
                        <textarea
                            id='comment'
                            rows={5}
                            onChange={(e) => setContent(e.target.value)}
                            value={content}
                            className='w-full p-2 text-sm text-black transition-transform duration-300 border border-gray-300 rounded-lg focus:ring-0 focus:outline-none'
                            placeholder='Write a comment...'
                            required
                        />
                        <div className='flex justify-end'>
                            <button className='mt-4 px-4 py-2 text-white bg-black rounded-lg hover:bg-spring-green-900'>
                                Post Comment
                            </button>
                        </div>
                    </form>
                </div>
                <div className='w-full mt-5 rounded-lg border hover:border-gray-700 shadow-lg p-5 transition-transform duration-300 '>
                    <h2 className='text-2xl font-bold mb-4'>Comments</h2>
                    <PostCommentCard />
                </div>
            </div>
        </section>

    )
}


export default PostCard