import React from 'react';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import LandingPage from './pages/landingPage/LandingPage';
import LoginPage from './pages/login/LoginPage';
import SignUpPage from './pages/signUp/SignUpPage';
import PrivateRoute from './PrivateRoute';
import UserDashboard from './pages/user/userDashboard/UserDashboard';
import RecipesDashboard from './pages/recipes/recipesDashboard/RecipesDashboard';
import AddRecipePage from './pages/recipes/addRecipe/AddRecipePage';
import AddPostPage from './pages/posts/addPost/AddPostPage';
import PostDashboard from './pages/posts/postDashboard/PostDashboard';
import SearchPostPage from './pages/posts/searchPost/SearchPostPage';
import PostDetailsPage from './pages/posts/postDetails/PostDetailsPage';
import SearchRecipePage from './pages/recipes/searchRecipe/SearchRecipePage';
import RecipeDetailsPage from './pages/recipes/recipeDetails/RecipeDetailsPage';
import UserSettingsPage from './pages/user/userSettings/UserSettingsPage';
import EditRecipePage from './pages/recipes/editRecipe/EditRecipePage';
import EditPostPage from './pages/posts/editPost/EditPostPage';

function App() {

  return (


    <BrowserRouter>
      <Routes>
        <Route path = '/' element={<LandingPage/>}/>
        <Route path = '/login' element={<LoginPage/>}/>
        <Route path = '/signup' element={<SignUpPage/>}/>
        <Route path = '/recipeDetails/:id' element={<RecipeDetailsPage/>}/>

        {/* private route */}
        <Route element = {<PrivateRoute/>}>
          <Route path = '/userDashboard' element = {<UserDashboard/>}/>
          <Route path = '/userSettings' element = {<UserSettingsPage/>}/>
          <Route path = '/recipesDashboard' element = {<RecipesDashboard/>}/>
          <Route path = '/addRecipe' element = {<AddRecipePage/>}/>
          <Route path = '/searchRecipe' element = {<SearchRecipePage/>}/>
          <Route path = '/addPost' element = {<AddPostPage/>}/>
          <Route path = '/postDashboard' element = {<PostDashboard/>}/>
          <Route path = '/searchPost' element = {<SearchPostPage/>}/>
          <Route path = '/postDetails/:id' element = {<PostDetailsPage/>}/>
          <Route path = '/editRecipe/:id' element = {<EditRecipePage/>}/>
          <Route path = '/editPost/:id' element = {<EditPostPage/>}/>
        </Route>
      </Routes>
    </BrowserRouter>   

  )
}

export default App;
