import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../axios/axiosSetup';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface User {
    email: string;
    username: string;
}

const UserCard: React.FC = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);


    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No token found');
                }

                const decoded: any = jwtDecode(token);
                const userId = decoded.id;

                const response = await axiosInstance.get(`/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setUser(response.data);
            } catch (error) {
                console.error('Failed to fetch user information:', error);
            }
        };


        fetchUserInfo();
    }, []);

    if (!user) {
        return <div>Loading</div>
    }


    return (
        <section className='flex flex-col h-full mt-20  max-sm:items-center  m-5 md:ml-80 lg:ml-80'>
            <div className='bg-white rounded-lg shadow-lg p-8 w-full h-full max-w-sm border hover:border-black'>
                <h5 className='mb-1 text-xl text-center text-black font-bold'>{user.username}</h5>
                <h1 className='mb-1 text-xl text-center  text-spring-green-900 font-bold'>{user.email}</h1>
            </div>
        </section>
    )
}


export default UserCard