import React from "react";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import RecipeCard from "./RecipeCard";


const RecipeDetailsPage: React.FC = () => {
    return ( 
        <div className="flex">
            <SideNavBar/>
            <RecipeCard/>
        </div>
    )
}



export default RecipeDetailsPage