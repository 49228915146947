import React from "react";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import UserCard from "./UserCard";



const UserDashboard: React.FC = () => {
    return (
        <div className="flex">
            <SideNavBar />
            <div className="flex-grow">
                <UserCard />
            </div>
        </div>
    )
}


export default UserDashboard