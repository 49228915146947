import React from "react";
import { useNavigate } from "react-router-dom";




interface Recipe {
    _id: string;
    name: string;
    author:string;
    prepHours:string;
    prepMinutes:string;
    ingredients:string[];
    instructions:string;
    image:string;
}

interface RecipeCardForSearchRecipePage {
    recipe:Recipe;
}

const LandingPageRecipeCard:React.FC<RecipeCardForSearchRecipePage> = ({recipe}) => {
    const navigate = useNavigate();

    return(
        <section className="w-120 mx-auto bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md transition-transform transform hover:scale-105 hover:shadow-xl my-2 p-4 sm:p-6">
        <div className="flex flex-col h-full">
            <div className="mb-4">
                <img 
                    src={recipe.image} 
                    alt={recipe.name} 
                    className="w-full h-48 object-cover rounded-lg mb-4"
                />
                <div className="font-bold text-xl sm:text-2xl mb-2 text-gray-900">
                    {recipe.name}
                </div>
                <div className="flex items-center text-gray-700">
                    <div className="flex-grow">
                        <div><strong>Author:</strong> {recipe.author}</div>
                        <div><strong>Prep Time:</strong> {recipe.prepHours}h {recipe.prepMinutes}m</div>
                    </div>
                    <div className="mt-4 sm:mt-0 ml-4 sm:ml-6 text-right">
                        <button
                            onClick={() => navigate(`/recipeDetails/${recipe._id}`)}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 sm:px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 transition-colors">
                            View Recipe
                        </button>
                    </div>
                </div>
            </div>
            {/* Additional content or footer if needed */}
        </div>
    </section>
    )
}


export default LandingPageRecipeCard;