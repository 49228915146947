import React from "react";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import AddPostForm from "./AddPostForm";



const AddPostPage: React.FC = () => {
    return( 
        <div className="flex">
            <SideNavBar/>
            <AddPostForm/>
        </div>
    )
}


export default AddPostPage