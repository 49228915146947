import { jwtDecode } from 'jwt-decode';
import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosSetup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Post {
    _id: string;
    postTitle: string;
    postAuthor: string;
    postDescription: string;
    postDate: string;
    postHashtags: string;
}

const EditPostForm: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        postTitle: '',
        postAuthor: '',
        postDescription: "",
        postDate: "",
        postHashtags: ""
    });
    const [postDate, setPostDate] = useState<Date | null>(null);
    const [userId, setUserId] = useState("");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        const fetchPost = async () => {
            try {
                const response = await axiosInstance.get(`/posts/${id}`);
                const { postTitle, postAuthor, postDescription, postDate, postHashtags } = response.data;
                setFormData({
                    postTitle,
                    postDescription,
                    postAuthor,
                    postDate,
                    postHashtags,
                });
                setPostDate(new Date(postDate));
            } catch (error) {
                console.error('Failed to fetch post details', error);
            }
        };

        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error("No token found");
                }
                const decoded: any = jwtDecode(token);
                const userId = decoded.id;
                setUserId(userId);

                await axiosInstance.get(`/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
            } catch (err) {
                console.error("Failed to fetch user information", err);
            }
        };

        fetchUserInfo();
        fetchPost();
    }, [id]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date: Date | null) => {
        setPostDate(date);
    };

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!formData.postTitle.trim()) newErrors.postTitle = 'Post title is required';
        if (!formData.postAuthor.trim()) newErrors.postAuthor = 'Post author is required';
        if (!postDate) newErrors.postDate = 'Post date is required';
        if (!formData.postDescription.trim()) newErrors.postDescription = 'Post description is required';
        if (!formData.postHashtags.trim()) newErrors.postHashtags = 'Post hashtags are required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) return;

        const data = {
            ...formData,
            postDate: postDate ? postDate.toISOString() : '',
            userId,
        };

        try {
            console.log(data);  // Log data to debug
            const res = await axiosInstance.put(`/posts/${id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(res.data);
            navigate(`/postsDashboard`);
        } catch (err) {
            console.error('Error updating post', err);
        }
    };

    return (
        <section className="flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80">
            <div className="bg-white shadow-2xl rounded-xl px-8 pt-6 pb-8 mb-4 w-full max-w-4xl">
                <h1 className='text-2xl font-bold text-black text-center mb-4 hover:text-spring-green-900'>Edit Post</h1>
                <form onSubmit={handleSubmit} className='flex flex-col items-center w-full'>
                    <div className='space-y-6 w-full max-w-lg'>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold'>Post Title</label>
                            <input
                                id='postTitle'
                                name='postTitle'
                                type='text'
                                required
                                value={formData.postTitle}
                                onChange={handleInputChange}
                                placeholder='Enter Post title'
                                className={`shadow appearance-none border rounded w-full py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postTitle ? 'border-red-500' : ''}`}
                            />
                            {errors.postTitle && <p className='text-red-500 text-xs mt-1'>{errors.postTitle}</p>}
                        </div>
                        <div className='grid grid-cols-2 space-x-5 w-full'>
                            <div>
                                <label className='block text-gray-700 text-sm font-bold'>Post Author</label>
                                <input
                                    id='postAuthor'
                                    name='postAuthor'
                                    type='text'
                                    required
                                    value={formData.postAuthor}
                                    onChange={handleInputChange}
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postAuthor ? 'border-red-500' : ''}`}
                                />
                                {errors.postAuthor && <p className='text-red-500 text-xs mt-1'>{errors.postAuthor}</p>}
                            </div>
                            <div>
                                <label className='block text-gray-700 text-sm font-bold'>Post Date</label>
                                <DatePicker
                                    selected={postDate}
                                    onChange={handleDateChange}
                                    dateFormat='yyyy-MM-dd'
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postDate ? 'border-red-500' : ''}`}
                                    placeholderText='Select Post Date'
                                />
                                {errors.postDate && <p className='text-red-500 text-xs mt-1'>{errors.postDate}</p>}
                            </div>
                        </div>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold'>Description</label>
                            <textarea
                                id='postDescription'
                                name='postDescription'
                                required
                                value={formData.postDescription}
                                onChange={handleInputChange}
                                placeholder='Enter Post Description'
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postDescription ? 'border-red-500' : ''}`}
                                rows={3} // Adjust number of rows as needed
                            />
                            {errors.postDescription && <p className='text-red-500 text-xs mt-1'>{errors.postDescription}</p>}
                        </div>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold'>Hashtags</label>
                            <input
                                id='postHashtags'
                                name='postHashtags'
                                type='text'
                                required
                                value={formData.postHashtags}
                                onChange={handleInputChange}
                                placeholder='Enter Post Hashtags'
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postHashtags ? 'border-red-500' : ''}`}
                            />
                            {errors.postHashtags && <p className='text-red-500 text-xs mt-1'>{errors.postHashtags}</p>}
                        </div>
                        <div className='flex items-center justify-center w-full'>
                            <button
                                type='submit'
                                className='bg-black hover:bg-spring-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default EditPostForm;