import React from "react";

const LandingPageHeader: React.FC = () => {
    return (
        <section className="flex flex-col items-center  w-full mt-10 p-5">
            <div className="w-full flex flex-col items-center">
                <h1 className="text-2xl font-bold text-spring-green-900 text-center mb-4">
                    Welcome To the Global Recipe Book
                </h1>
                <p className="relative max-w-2xl text-center 
before:absolute before:inset-0 before:bg-white
before:animate-typewriter">
                    Welcome to our vibrant community space! Here, food enthusiasts can connect, share recipes, collaborate, and indulge in all things culinary. Join us to explore, create and celebrate the joy of food together.
                </p>
            </div>
        </section>
    );
};

export default LandingPageHeader;
