import React ,{useEffect,useState} from 'react'
import axiosInstance from '../../../axios/axiosSetup'
import { useNavigate } from 'react-router-dom'
import SearchBarForRecipe from './SearchBarForRecipe'
import SearchRecipeCard from './SearchRecipeCard'

const SearchRecipeApplication:React.FC = () => {
    const [recipes,setRecipes] = useState<any[]>([]);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedSearchBarData = localStorage.getItem("searchBarValue");
                let response
                if(storedSearchBarData){
                    response = await axiosInstance.get('/recipe/search',{
                        params:{name:storedSearchBarData}
                    })
                }else {
                    response = await axiosInstance.get('recipe/all');
                }
                if (Array.isArray(response.data)){
                    setRecipes(response.data)
                }
            }catch(err){
                console.error("Error fetching recipes",err)
            }
        }
        fetchData();
    },[])

    const handleSearchResults = (searchResults:any[]) => {
        setRecipes(searchResults)
    }

    const handleAddRecipe = () => {
        navigate("/addRecipe")
    }

    return(
        <section className='max-lg:p-20  md:ml-80 lg:ml-80 lg:px-8'>
        <SearchBarForRecipe onSearch={handleSearchResults} />
        <div className="hide-scrollbar max-md:items-center items-center justify-center max-md:justify-center flex">
            {recipes.length === 0 ? (
                <div className="text-center mt-10">
                    <p>No recipes found. Would you like to add one?</p>
                    <button
                        onClick={handleAddRecipe}
                        className="mt-4 bg-black hover:bg-spring-green-900 text-white px-4 py-2 rounded"
                    >
                        Add Recipe
                    </button>
                </div>
            ) : (
                <div className="flex flex-col  gap-6 mt-10"> {/* Use flex-col to stack items vertically */}
                    {recipes.map((recipe) => (
                        <div key={recipe._id} className="transform transition duration-500">
                            <SearchRecipeCard recipe={recipe} />
                        </div>
                    ))}
                </div>
            )}
        </div>
    </section>
    )
}


export default SearchRecipeApplication