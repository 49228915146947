import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../axios/axiosSetup';
import { useNavigate } from 'react-router-dom';
import SearchBarForPost from './SearchBarForPost';
import SearchPostCard from './SearchPostCard';

const SearchPostApplication: React.FC = () => {
    const [posts, setPosts] = useState<any[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedSearchBarData = localStorage.getItem("searchBarValue");
                let response;
                if (storedSearchBarData) {
                    response = await axiosInstance.get('/posts/search', {
                        params: { title: storedSearchBarData }
                    });
                } else {
                    response = await axiosInstance.get('/posts/all');
                    console.log(response);
                }
                if (Array.isArray(response.data)) {
                    setPosts(response.data);
                }
            } catch (err) {
                console.error("Error fetching posts", err);
            }
        };

        fetchData();
    }, []);

    const handleSearchResults = (searchResults: any[]) => {
        setPosts(searchResults);
    };

    const handleAddPost = () => {
        navigate('/addPost');
    };

    return (
        <section className='max-lg:p-20  md:ml-80 lg:ml-80 lg:px-8'>
            <SearchBarForPost onSearch={handleSearchResults} />
            <div className="hide-scrollbar max-md:items-center items-center justify-center max-md:justify-center flex">
                {posts.length === 0 ? (
                    <div className="text-center mt-10">
                        <p>No posts found. Would you like to add one?</p>
                        <button
                            onClick={handleAddPost}
                            className="mt-4 bg-black hover:bg-spring-green-900 text-white px-4 py-2 rounded"
                        >
                            Add Recipe
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-col  gap-6 mt-10"> {/* Use flex-col to stack items vertically */}
                        {posts.map((post) => (
                            <div key={post._id} className="transform transition duration-500">
                                <SearchPostCard post={post} />
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </section>
    );
};

export default SearchPostApplication;
