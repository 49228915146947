
import React, { ChangeEvent, useState, FormEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import axiosInstance from "../../../axios/axiosSetup";




function AddRecipeForm() {
    const [formData, setFormData] = useState({
        name: "",
        author: "",
        prepHours: "0",
        prepMinutes: "0",
        ingredients: "",
        instructions: "",
    });
    const [userId, setUserId] = useState('');

    const navigate = useNavigate();

    const [file, setFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            setFile(selectedFile);

            const fileReader = new FileReader();
            fileReader.onload = () => {
                setPreviewUrl(fileReader.result as string);
            };
            fileReader.readAsDataURL(selectedFile);
        }
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');

                if (!token) {
                    throw new Error('No token found');
                }

                // Decode the token to get the user ID if necessary
                const decoded: any = jwtDecode(token);
                const userId = decoded.id;
                setUserId(userId); // Set the userId in the state

                // Optionally, you can fetch more user information if needed
                const response = await axiosInstance.get(`/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                console.log(response.data);
                
            } catch (error) {
                console.error('Failed to fetch user information:', error);
            }
        };

        fetchUserInfo();
    }, []);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = new FormData();
        data.append("name", formData.name);
        data.append("author", formData.author);
        data.append("prepHours", formData.prepHours);
        data.append("prepMinutes", formData.prepMinutes);
        data.append("ingredients", formData.ingredients);
        data.append("instructions", formData.instructions);
        data.append("userId", userId); // Add userId to the form data
        console.log(userId)
        if (file) {
            data.append("file", file);
        }

        try {
            const response = await axiosInstance.post('/recipe/create', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log(response.data);
            navigate('/');
        } catch (err) {
            console.error("Error with submitting form", err);
        }
    };

    return (
      <section className="flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80">
        <div className="w-full overflow-x-auto">
        <h1 className="text-2xl font-bold text-spring-green-900 text-center mb-4 ">Add Recipe</h1>
        <p className="text-center mb-6">
            Please add your recipe here , make sure to include an image
        </p>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 p-5 md:grid-cols-2 gap-6">
                    <div className="space-y-6">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Recipe Name</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                required
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Enter recipe name"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Author</label>
                            <input
                                id="author"
                                name="author"
                                type="text"
                                required
                                value={formData.author}
                                onChange={handleInputChange}
                                placeholder="Enter author name"
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Prep Time</label>
                            <div className="flex space-x-2">
                                <select
                                    name="prepHours"
                                    value={formData.prepHours}
                                    onChange={handleInputChange}
                                    className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option disabled value="">Hours</option>
                                    {Array.from(Array(24), (_, i) => (
                                        <option key={i} value={i}>{i}</option>
                                    ))}
                                </select>
                                <select
                                    name="prepMinutes"
                                    value={formData.prepMinutes}
                                    onChange={handleInputChange}
                                    className="shadow appearance-none border rounded w-1/2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                >
                                    <option disabled value="">Minutes</option>
                                    {Array.from(Array(60), (_, i) => (
                                        <option key={i} value={i}>{i}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Ingredients</label>
                            <textarea
                                id="ingredients"
                                name="ingredients"
                                required
                                value={formData.ingredients}
                                onChange={handleInputChange}
                                placeholder="Enter ingredients"
                                rows={6}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </div>
                    <div className="space-y-6">
                        <div>
                            <label className="block text-gray-700 text-sm font-bold mb-2">Instructions</label>
                            <textarea
                                id="instructions"
                                name="instructions"
                                required
                                value={formData.instructions}
                                onChange={handleInputChange}
                                placeholder="Enter instructions"
                                rows={6}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div>
                            <input
                                type="file"
                                name="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                className="hidden"
                                id="fileInput"
                            />
                            <label
                                htmlFor="fileInput"
                                className="flex items-center justify-center w-full bg-black hover:bg-spring-green-900 text-white py-2 px-4 rounded cursor-pointer hover:bg-forest-green-700"
                            >
                                Upload Image
                            </label>
                            {previewUrl && (
                                <img
                                    src={previewUrl}
                                    alt="Image Preview"
                                    className="mt-4 w-full h-48 object-cover rounded shadow"
                                />
                            )}
                        </div>
                        <div className="flex justify-center">
                            <button
                                className="bg-black hover:bg-spring-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                                type="submit"
                            >
                                Add Recipe
                            </button>
                        </div>
                    </div>
                </form>
 
        </div>
      </section>
    );
}

export default AddRecipeForm;
