import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NavbarForLoginAndSignup: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isUserSideBarOpen, setIsUserSideBarOpen] = useState(false);
    const [isRecipesSideBarOpen, setIsRecipesSideBarOpen] = useState(false);
    const [isPostSideBarOpen, setIsPostSideBarOpen] = useState(false);
    const navigate = useNavigate();

    // Authentication 
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            setIsLoggedIn(!!token);
        };
        fetchData();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleUserSideBar = () => {
        setIsUserSideBarOpen(!isUserSideBarOpen);
    };

    const toggleRecipeSideBar = () => {
        setIsRecipesSideBarOpen(!isRecipesSideBarOpen);
    };

    const togglePostSideBar = () => {
        setIsPostSideBarOpen(!isPostSideBarOpen);
    };

    const handleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
        navigate('/');
        window.location.reload();
    };

    return (
        <section>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 text-black">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start w-full space-x-2 md:space-x-2 lg:space-x-0 ">
                         
                            <a href="/" className="flex items-center">
                                <span className="self-center font-semibold text-sm sm:text-base lg:text-lg whitespace-nowrap text-black hover:text-spring-green-900 hover:scale-105">
                                    The Global Recipe Book
                                </span>
                            </a>
                        </div>
                        <div className="flex-grow"></div>
                        <ul className='flex space-x-2 sm:space-x-4 md:space-x-6 lg:space-x-10 text-xs sm:text-sm md:text-base lg:text-lg'>
                            <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                <a href='/userDashboard'>User</a>
                            </li>
                            <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                <a href='/recipesDashboard'>Recipes</a>
                            </li>
                            <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                <a href='/postDashboard'>Posts</a>
                            </li>
                            {isLoggedIn ? (
                                <li className='hover:text-spring-green-500 hover:scale-110 transition-transform'>
                                    <a onClick={handleLogout}>Logout</a>
                                </li>
                            ) : (
                                <>
                                    <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                        <a href='/login'>Login</a>
                                    </li>
                                    <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                        <a href='/signup'>SignUp</a>
                                    </li>
                                </>
                            )}
                        </ul>
                        <div className="flex items-center ms-3">
                            <button
                                type="button"
                                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                aria-expanded="false"
                                data-dropdown-toggle="dropdown-user"
                            >
                                <span className="sr-only">Open user menu</span>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

          
        </section>
    );
};

export default NavbarForLoginAndSignup;
