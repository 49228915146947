import React,{FormEvent,useState} from 'react';
import axiosInstance from '../../axios/axiosSetup';
import { useNavigate } from 'react-router-dom';


const SignUpForm: React.FC = ()  => {
    const navigate = useNavigate();

    const [formData,setFormData] = useState({
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
    })
    const [passwordMatch,setPasswordMatch] = useState(true);


    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if(formData.password !== formData.confirmPassword){
            setPasswordMatch(false);
            return;
        }

        try {
            const response = await axiosInstance.post('/auth/signup',formData);
            const token = response.data.token;
            localStorage.setItem('token',token)
            navigate('/');
        }catch(err){
            console.log("Error with signing up ")
        }
    }

    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const {name,value} = e.target;
        setFormData({...formData,[name]:value});
        setPasswordMatch(true)
    }


    const gtoLoginPage = () => {
        navigate('/login')
    }

    return ( 
        <section className='flex flex-col items-center justify-center h-screen '>
        <div className='bg-white px-8 pt-6 pb-8 mb-4 w-full max-w-lg'>
          <h1 className='text-2xl font-bold text-spring-green-900 text-center mb-4'>Register</h1>
          <p className='text-center mb-6'>
            Please make an account to get started sharing and looking for recipes to add to your cookbook.
          </p>
          <form onSubmit={handleSubmit}>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
                Email
              </label>
              <input required value={formData.email} name='email' onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' id='email' type='email' placeholder='example@gmail.com' />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='username'>
                Username
              </label>
              <input required value={formData.username} name='username' onChange={handleInputChange} className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline' id='username' type='text' placeholder='Username' />
            </div>
            <div className='mb-4'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
                Password
              </label>
              <input required value={formData.password} name='password' onChange={handleInputChange} className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!passwordMatch ? 'border-red-500' : ''}`} id='password' type='password' placeholder='**********' />
            </div>
            <div className='mb-6'>
              <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='confirmPassword'>
                Confirm Password
              </label>
              <input required value={formData.confirmPassword} name='confirmPassword' onChange={handleInputChange} className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${!passwordMatch ? 'border-red-500' : ''}`} id='confirmPassword' type='password' placeholder='**********' />
              {!passwordMatch && <p className='text-red-500 text-xs italic'>Passwords do not match.</p>}
            </div>
            <p className='text-center mb-6'>
              Already have an account? <button type="button" onClick={gtoLoginPage} className="text-blue-500 hover:text-blue-700">Sign in</button>
            </p>
            <div className='flex items-center justify-center'>
              <button type='submit' className='bg-black  hover:bg-spring-green-900 text-white hover:test-spring-green-900 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
                Register
              </button>
            </div>
          </form>
        </div>
      </section>
    )



}


export default SignUpForm