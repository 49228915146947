import React, {useEffect,useState} from 'react';
import axiosInstance from '../../../axios/axiosSetup';
import {jwtDecode} from 'jwt-decode'
import { useNavigate } from 'react-router-dom';
import SideNavBar from '../../../components/sidebar/SideNavBar';
import PostTable from './PostTable';





const PostDashboard: React.FC = () => {
    const navigate = useNavigate();
    const [posts,setPosts] = useState<any[]>([])

    useEffect(() => {
        const fetchPostsInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if(!token){
                    throw new Error("No toekn found")
                }

                const decoded: any = jwtDecode(token);
                const userId = decoded.id;

                const response = await axiosInstance.get(`/posts/getUserPosts?userId=${userId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  })
                  setPosts(response.data)
            }catch(error){
                console.log("There must be an error with the Authentication or token",error)
            }
        }
        fetchPostsInfo();
    },[])

    return (
        <div className='flex'>
            <SideNavBar/>
            <PostTable posts={posts}/>

        </div>
    )

}

export default PostDashboard