import React from "react";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import UserCard from "./UserCard";
import UserSettingsForm from "./UserSettingsForm";



const UserSettingsPage: React.FC = () => {
    return(
        <div className="flex">
            <SideNavBar/>
            {/* <UserCard/> */}
            <UserSettingsForm/>
        </div>
    )
}


export default UserSettingsPage;