import React,{useEffect,useState} from "react";
import axiosInstance from "../../../axios/axiosSetup";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import RecipesTable from "./RecipesTable";
import SideNavBar from "../../../components/sidebar/SideNavBar";



const RecipesDashboard:React.FC = () => {
    const navigate = useNavigate();
    const [recipes,setRecipes] = useState<any[]>([])


    useEffect(() => {
        const fetchRecipesInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if(!token){
                    throw new Error("No token found")
                }
                const decoded: any = jwtDecode(token);
                const userId = decoded.id;
                const response = await axiosInstance.get(`/recipe/getUserRecipes?userId=${userId}`, {
                    headers: {
                      Authorization: `Bearer ${token}`
                    }
                  });
                setRecipes(response.data)
            }catch(error){
                console.log("There must be an error with the Autenitcation or toekn",error)
            }

        }
        
        fetchRecipesInfo();

    },[])


    return(
            <div className="flex">
                <SideNavBar/>


                <RecipesTable recipes={recipes}/>

            </div>
    )
}

export default RecipesDashboard