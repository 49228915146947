import React from "react";
import { useNavigate } from "react-router-dom";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import EditRecipeForm from "./EditRecipeForm";



const EditRecipePage:React.FC = () => {
    const navigate = useNavigate();

    return(
        <div className="flex">
            <SideNavBar/>
            <EditRecipeForm />
        </div>
    )
}

export default EditRecipePage