import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../axios/axiosSetup';
import {jwtDecode} from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface User {
    email: string;
    username: string;
}

const UserSettingsForm: React.FC = () => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [confirmationDelete, setConfirmationDelete] = useState('');

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error("No token found");
                }

                const decoded: any = jwtDecode(token);
                const userId = decoded.id;

                const response = await axiosInstance.get(`/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setUser(response.data);
            } catch (error) {
                console.error("Failed to fetch user information:", error);
            }
        };

        fetchUserInfo();
    }, []);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleDeleteAccount = () => {
        if (confirmationDelete === 'confirm') {
            const token = localStorage.getItem('token');
            if (!token) {
                return;
            }

            const decoded: any = jwtDecode(token);
            const userId = decoded.id;

            axiosInstance.delete(`auth/${userId}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(() => {
                localStorage.removeItem('token');
                navigate('/');
            }).catch((error) => {
                console.error("Failed to delete account:", error);
            });
        } else {
            openModal();
        }
    }

    return (
        <section className='flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80'>
            <div className='w-full overflow-x-auto'>
                <h1 className='text-2xl font-bold text-spring-green-900 text-center mb-4'>User Settings</h1>
            </div>
            <div className='flex mt-5 rounded-lg shadow-lg border p-10 w-full'>
                <div className='flex flex-col items-center space-y-10'>
                    <div>
                        <label className="block text-gray-700 text-sm font-bold mb-2">User Name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            placeholder={user?.username}
                            className="shadow appearance-none w-96 max-sm:w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div>
                        <label className='block text-gray-700 text-sm font-bold mb-2'>User Email</label>
                        <input
                            id='email'
                            name='email'
                            type='email'
                            placeholder={user?.email}
                            className="shadow appearance-none w-96  max-sm:w-full border placeholder-blue-700 text-blue-700 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                    <div className='w-full text-left'>
                        <button onClick={handleDeleteAccount} className='bg-black text-white p-3 rounded-lg focus:outline-none focus:shadow-outline hover:scale-105 hover:bg-red-500 transition'>
                            Delete Account
                        </button>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="fixed z-10 inset-0 flex items-center justify-center">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-sm"></div>
                    <div className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full">
                        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                        Confirm Deletion
                                    </h3>
                                    <div className="mt-2">
                                        <p className="text-sm text-gray-500">
                                            Are you sure you want to delete your account? This action cannot be undone. Please type "confirm" to proceed.
                                        </p>
                                        <input
                                            type="text"
                                            value={confirmationDelete}
                                            onChange={(e) => setConfirmationDelete(e.target.value)}
                                            className="mt-2 shadow appearance-none w-full border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 ${
                                    confirmationDelete === 'confirm' ? 'bg-red-600 hover:bg-red-700' : 'bg-gray-400 cursor-not-allowed'
                                } text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm`}
                                onClick={handleDeleteAccount}
                                disabled={confirmationDelete !== 'confirm'}
                            >
                                Delete
                            </button>
                            <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={closeModal}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
};

export default UserSettingsForm;
