import React from 'react';
import LoginForm from './LoginForm';
import NavbarForLoginAndSignup from '../../components/sidebar/NavbarForLoginAndSignup';

const LoginPage: React.FC = () => {
    return (
        <div className='flex'>
            <NavbarForLoginAndSignup/>
            <div className='flex-grow'>
                <LoginForm />
            </div>
        </div>
    )
}


export default LoginPage