import React, { ChangeEvent, useState, FormEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import axiosInstance from "../../../axios/axiosSetup";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'


function AddPostForm(){
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        postTitle: "",
        postAuthor: '',
        postDescription: '',
        postHashtags: '',
    });
    const [postDate, setPostDate] = useState<Date | null>(null);
    const [userId, setUserId] = useState('');
    const [username, setUsername] = useState('');
    const [errors, setErrors] = useState<{ [key: string]: string }>({});

    const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (date: Date | null) => {
        setPostDate(date);
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('No token found');
                }
                const decoded: any = jwtDecode(token);
                const userId = decoded.id;
                setUserId(userId);

                const response = await axiosInstance.get(`/users/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const userData = response.data;
                setUsername(userData.username);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    postAuthor: userData.username,
                }));
            } catch (error) {
                console.error('Failed to fetch user information:', error);
            }
        };
        fetchUserInfo();
    }, []);

    const validateForm = () => {
        const newErrors: { [key: string]: string } = {};

        if (!formData.postTitle.trim()) newErrors.postTitle = 'Post title is required';
        if (!formData.postAuthor.trim()) newErrors.postAuthor = 'Post author is required';
        if (!postDate) newErrors.postDate = 'Post date is required';
        if (!formData.postDescription.trim()) newErrors.postDescription = 'Post description is required';
        if (!formData.postHashtags.trim()) newErrors.postHashtags = 'Post hashtags are required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) return;

        const data = {
            ...formData,
            postDate: postDate ? postDate.toISOString() : '',
            userId,
        };

        try {
            console.log(data);  // Log data to debug
            const res = await axiosInstance.post('/posts/create', data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(res.data);
            navigate('/userDashboard')
        } catch (err) {
            console.error('Error with post form', err);
        }
    };

    return ( 
        <section className="flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80">
            <div className="w-full overflow-auto">
            <h1 className='text-2xl font-bold text-spring-green-900 text-center mb-4'>Add Post</h1>
                <p className='text-center mb-6'>
                    Need help making a recipe or want to share something, add your post.
                </p>
                <form onSubmit={handleSubmit} className='flex flex-col items-center w-full'>
                    <div className='space-y-6 w-full max-w-lg'>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold'>Post Title</label>
                            <input
                                id='postTitle'
                                name='postTitle'
                                type='text'
                                required
                                value={formData.postTitle}
                                onChange={handleInputChange}
                                placeholder='Enter Post title'
                                className={`shadow appearance-none border rounded w-full py-2 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postTitle ? 'border-red-500' : ''}`}
                            />
                            {errors.postTitle && <p className='text-red-500 text-xs mt-1'>{errors.postTitle}</p>}
                        </div>
                        <div className='grid grid-cols-2 space-x-5 w-full'>
                            <div>
                                <label className='block text-gray-700 text-sm font-bold'>Post Author</label>
                                <select
                                    id='postAuthor'
                                    name='postAuthor'
                                    required
                                    value={formData.postAuthor}
                                    onChange={handleInputChange}
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postAuthor ? 'border-red-500' : ''}`}
                                >
                                    <option value={username}>{username}</option>
                                    {/* Add more options here if necessary */}
                                </select>
                                {errors.postAuthor && <p className='text-red-500 text-xs mt-1'>{errors.postAuthor}</p>}
                            </div>
                            <div>
                                <label className='block text-gray-700 text-sm font-bold'>Post Date</label>
                                <DatePicker
                                    selected={postDate}
                                    onChange={handleDateChange}
                                    dateFormat='yyyy-MM-dd'
                                    className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postDate ? 'border-red-500' : ''}`}
                                    placeholderText='Select Post Date'
                                />
                                {errors.postDate && <p className='text-red-500 text-xs mt-1'>{errors.postDate}</p>}
                            </div>
                        </div>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold'>Description</label>
                            <textarea
                                id='postDescription'
                                name='postDescription'
                                required
                                value={formData.postDescription}
                                onChange={handleInputChange}
                                placeholder='Enter Post Description'
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postDescription ? 'border-red-500' : ''}`}
                                rows={3} // Adjust number of rows as needed
                            />
                            {errors.postDescription && <p className='text-red-500 text-xs mt-1'>{errors.postDescription}</p>}
                        </div>
                        <div>
                            <label className='block text-gray-700 text-sm font-bold'>Hashtags</label>
                            <input
                                id='postHashtags'
                                name='postHashtags'
                                type='text'
                                required
                                value={formData.postHashtags}
                                onChange={handleInputChange}
                                placeholder='Enter Post Hashtags'
                                className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${errors.postHashtags ? 'border-red-500' : ''}`}
                            />
                            {errors.postHashtags && <p className='text-red-500 text-xs mt-1'>{errors.postHashtags}</p>}
                        </div>
                        <div className='flex items-center justify-center w-full'>
                            <button
                                type='submit'
                                className='bg-black hover:bg-spring-green-900 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
    
            </div>
        </section>
    )
}


export default AddPostForm