import React from "react";
import NavbarForLoginAndSignup from "../../components/sidebar/NavbarForLoginAndSignup";
import LandingPageHeader from "./LandingPageComponents/LandingPageHeader";
import LandingPageApplicationRecipe from "./LandingPageComponents/LandingPageRecipeComponent/LandingPageApplicationRecipe";
import LandingPageApplicationPost from "./LandingPageComponents/LandingPagePostComponent/LandingPageApplicationPost";

const LandingPage: React.FC = () => {
    return (
        <div className="flex flex-col bg-gray-100">
            <NavbarForLoginAndSignup />
            <LandingPageHeader />
            <div className="flex flex-col lg:flex-row justify-center p-5 lg:p-20 gap-5 lg:gap-10">
                <LandingPageApplicationRecipe />
                <LandingPageApplicationPost />
            </div>
        </div>
    );
}

export default LandingPage;