import React from "react";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import AddRecipeForm from "./AddRecipeForm";

const AddRecipePage: React.FC = () => {
    return(
        <div className="flex">
            <SideNavBar/>
            <AddRecipeForm/>

        </div>
    )
}


export default AddRecipePage