import React,{FormEvent,useState} from "react";
import axiosInstance from "../../axios/axiosSetup";
import { useNavigate } from "react-router-dom";


const LoginForm: React.FC = () => {
    const navigate = useNavigate();
    const [formData,setFormData] = useState({
        email:"",
        password:"",
    })

    const [error, setError] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError(null); // Clear error message when user types
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.post('/auth/login', formData);
      const token = response.data.token;
      localStorage.setItem('token', token);
      navigate('/');
    } catch (err) {
      setError("Invalid credentials, please try again.");
    }
  };

  const gtoSignUpPage = () => {
    navigate('/signup');
  };

  return (
    <section className='flex flex-col items-center justify-center h-screen'>
      <div className='bg-white  px-1 pt-6 pb-8 mb-4 w-full max-w-md'>
        <h1 className='text-2xl font-bold text-spring-green-900 text-center mb-4'>Login</h1>
        <p className='text-center mb-6'>
          Please login to get started sharing and looking for recipes to add to your cookbook.
        </p>
        <form onSubmit={handleSubmit}>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='email'>
              Email
            </label>
            <input
              required
              value={formData.email}
              name='email'
              onChange={handleInputChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${error ? 'border-red-500' : ''}`}
              id='email'
              type='email'
              placeholder='example@gmail.com'
            />
          </div>
          <div className='mb-4'>
            <label className='block text-gray-700 text-sm font-bold mb-2' htmlFor='password'>
              Password
            </label>
            <input
              required
              value={formData.password}
              name='password'
              onChange={handleInputChange}
              className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${error ? 'border-red-500' : ''}`}
              id='password'
              type='password'
              placeholder='*********'
            />
          </div>
          {error && <p className='text-red-500 text-xs italic mb-4'>{error}</p>}
          <p className='text-center mb-6'>
            Don't have an account yet? <button type="button" onClick={gtoSignUpPage} className="text-blue-500 hover:text-blue-700">Sign Up</button>
          </p>
          <div className='flex items-center justify-center'>
            <button type='submit' className='bg-black text-white hover:bg-spring-green-900 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'>
              Login
            </button>
          </div>
        </form>
      </div>
    </section>
  )



}


export default LoginForm