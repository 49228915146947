import React , {FormEvent,useEffect,useState} from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosSetup';
import { jwtDecode } from 'jwt-decode';

interface Recipe {
    _id:string;
    name:string;
    author:string;
    prepHours:string;
    prepMinutes:string;
    ingredients:string;
    instructions:string;
    image:string;
}
interface User {
    username:string;
}

const RecipeCard:React.FC = () => {
    const {id} = useParams<{id:string}>();
    const [userId,setUserId] = useState('');
    const [recipe,setRecipe] = useState<Recipe | null>(null);
    const [user,setUser] = useState<User | null>(null);
    const [username,setUsername] = useState('');
    const [loading,setLoading] = useState(false);
    const [isLoggedIn,setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(!token){
            navigate("/login");
            return;
        }
        const decoded: any = jwtDecode(token);
        setIsLoggedIn(!!token);
        setUserId(decoded.id);

        const fetchUserInfo = async () => {
            try {
                const response = await axiosInstance.get(`/users/${decoded.id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setUser(response.data);
                setUsername(response.data.username);
            } catch (error) {
                console.error('Failed to fetch user information:', error);
            }
        };

        const fetchRecipe = async () => {
            try {
                const res = await axiosInstance.get(`/recipe/${id}`);
                setRecipe(res.data);
            }catch(err){
                console.error("Failed to fetch recipe details",err);
            }finally{
                setLoading(false);
            }
        }

        fetchUserInfo();
        fetchRecipe();

    },[id,navigate])

    if(loading){
        return ( 
            <div className='flex items-center justify-center min-h-screen bg-gray-100'>
                <div className='text-2xl font-semibold text-gray-600'>Loading...</div>
            </div>
        )
    }
    const handleLogin = () => {
        navigate('/login')
    }

    const handleSignUp = () => {
        navigate('/signup')
    }
    return ( 
        <section className='flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80'>
                <div className="bg-white rounded-2xl shadow-2xl m-5 p-8 w-full max-w-7xl">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                        <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-5 transition-transform transform hover:scale-105 hover:border-blue-500 hover:shadow-2xl">
                            <h1 className="text-4xl font-bold text-gray-800">{recipe?.name}</h1>
                            <p className="text-gray-600 mt-2">
                                <span className="font-semibold">By:</span> {recipe?.author}
                            </p> 
                            <p className="text-gray-600 mt-2">
                                <span className="font-semibold">Prep Time:</span> {recipe?.prepHours} hours {recipe?.prepMinutes} minutes
                            </p>
                        </div>
                        {recipe?.image && (
                            <div className="flex justify-center items-center">
                                <img src={recipe.image} alt={recipe.name} className="object-contain max-w-full max-h-96 rounded-lg border border-gray-200 shadow-lg transition-transform transform hover:scale-105 hover:border-blue-500 hover:shadow-2xl" />
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-5 transition-transform transform hover:scale-105 hover:border-blue-500 hover:shadow-2xl">
                            <h2 className="text-3xl font-semibold mb-5 text-gray-800">Ingredients</h2>
                            <ul className={`list-disc pl-5 text-gray-700 whitespace-pre-line ${!isLoggedIn && 'blur-md'}`}>
                                {recipe?.ingredients.split('\n').map((ingredient, index) => (
                                    <li key={index} className="mb-2">{ingredient}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="bg-white rounded-lg border border-gray-200 shadow-lg p-5 transition-transform transform hover:scale-105 hover:border-blue-500 hover:shadow-2xl">
                            <h2 className="text-3xl font-semibold mb-5 text-gray-800">Instructions</h2>
                            <ol className={`list-decimal pl-5 text-gray-700 whitespace-pre-line ${!isLoggedIn && 'blur-md'}`}>
                                {recipe?.instructions.split('\n').map((instruction, index) => (
                                    <li key={index} className="mb-3">{instruction}</li>
                                ))}
                            </ol>
                        </div>
                    </div>
                    {!isLoggedIn && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
                            <div className="bg-white rounded-lg p-8 shadow-lg text-center">
                                <h2 className="text-2xl font-semibold mb-4">To Access Full Recipe</h2>
                                <p className="mb-6 text-gray-600">please log in or sign up.</p>
                                <div className="flex justify-center space-x-4">
                                    <button
                                        onClick={handleLogin}
                                        className="bg-black text-white px-4 py-2 rounded-lg hover:bg-spring-green-900"
                                    >
                                        Log In
                                    </button>
                                    <button
                                        onClick={handleSignUp}
                                        className="bg-black text-white px-4 py-2 rounded-lg hover:bg-spring-green-900"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
    )

    
}


export default RecipeCard;


