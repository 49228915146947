import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosSetup';

interface Recipe {
    _id: string;
    name: string;
    author: string;
    prepHours: string;
    prepMinutes: string;
}

interface MyRecipesTableProps {
    recipes: Recipe[]
}

const RecipesTable: React.FC<MyRecipesTableProps> = ({ recipes }) => {
    const navigate = useNavigate();
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [recipeToDelete, setRecipeToDelete] = useState<string | null>(null);
    const [confirmName, setConfirmName] = useState("");
    const [nameMatches, setNameMatches] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const recipesPerPage = 10;

    const handleDeleteClick = (id: string, event: React.MouseEvent) => {
        event.stopPropagation();
        setRecipeToDelete(id);
        setIsPopupVisible(true);
        setConfirmName("");
        setNameMatches(false);
    }

    const handleConfirmDelete = async () => {
        if (recipeToDelete && nameMatches) {
            try {
                const response = await axiosInstance.delete(`/recipe/${recipeToDelete}`);
                if (response.status !== 200) {
                    throw new Error("Failed to delete the recipe");
                }

                setIsPopupVisible(false);
                setRecipeToDelete(null);
                window.location.reload();
            } catch (error) {
                console.error("Error deleting recipe:", error);
            }
        }
    }

    const handleCancelDelete = () => {
        setIsPopupVisible(false);
        setRecipeToDelete(null);
        setConfirmName("");
        setNameMatches(false);
    }

    const indexOfLastRecipe = currentPage * recipesPerPage;
    const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
    const currentRecipes = recipes.slice(indexOfFirstRecipe, indexOfLastRecipe);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(recipes.length / recipesPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    return (
        <section className='flex flex-col h-full mt-20 w-full items-center m-5 md:ml-80 lg:ml-80'>
            {/* Popup for Delete Confirmation */}
            {isPopupVisible && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded shadow-md text-center">
                        <h2 className="text-xl mb-4">Confirm Deletion</h2>
                        <p>Are you sure you want to delete this recipe?</p>
                        <p>Please type the recipe name "<strong>{recipes.find(recipe => recipe._id === recipeToDelete)?.name}</strong>" to confirm:</p>
                        <input
                            type="text"
                            className={`border ${nameMatches ? 'border-green-500' : 'border-red-500'} px-4 py-2 rounded mt-2`}
                            value={confirmName}
                            onChange={(e) => {
                                setConfirmName(e.target.value);
                                setNameMatches(e.target.value === recipes.find(recipe => recipe._id === recipeToDelete)?.name);
                            }}
                        />
                        <div className="mt-4 flex justify-center space-x-4">
                            <button onClick={handleConfirmDelete} disabled={!nameMatches} className={`bg-red-600 text-white px-4 py-2 rounded ${nameMatches ? '' : 'opacity-50 cursor-not-allowed'}`}>
                                Delete
                            </button>
                            <button onClick={handleCancelDelete} className="bg-gray-300 text-black px-4 py-2 rounded">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="w-full overflow-x-auto">
                <table className='min-w-full text-sm text-left rtl:text-right text-black'>
                    <thead className='text-xs text-black uppercase bg-gray-100'>
                        <tr>
                            <th scope='col' className='px-2 py-2 sm:px-6 sm:py-3 border-b border-gray-300'>Recipe Name</th>
                            <th scope='col' className='px-2 py-2 sm:px-6 sm:py-3 border-b border-gray-300'>Recipe Author</th>
                            <th scope='col' className='px-2 py-2 sm:px-6 sm:py-3 border-b border-gray-300'>Prep Hours</th>
                            <th scope='col' className='px-2 py-2 sm:px-6 sm:py-3 border-b border-gray-300'>Prep Minutes</th>
                            <th scope='col' className='px-2 py-2 sm:px-6 sm:py-3 border-b border-gray-300 text-center'>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentRecipes.map(recipe => (
                            <tr key={recipe._id} className='bg-white border-b border-gray-300'>
                                <td className='px-2 py-2 sm:px-6 sm:py-4 font-medium text-black'>{recipe.name}</td>
                                <td className='px-2 py-2 sm:px-6 sm:py-4'>{recipe.author}</td>
                                <td className='px-2 py-2 sm:px-6 sm:py-4'>{recipe.prepHours}</td>
                                <td className='px-2 py-2 sm:px-6 sm:py-4'>{recipe.prepMinutes}</td>
                                <td className='px-2 py-2 sm:px-6 sm:py-4 text-center'>
                                    <div className="flex justify-center space-x-2">
                                        <button
                                            className='bg-black text-white hover:scale-105 px-2 sm:px-4 py-1 sm:py-2 rounded hover:bg-spring-green-900 text-xs sm:text-sm'
                                            onClick={() => navigate(`/recipeDetails/${recipe._id}`)}
                                        >
                                            View
                                        </button>
                                        <button
                                            className='bg-blue-500 text-white hover:scale-105 px-2 sm:px-4 py-1 sm:py-2 rounded hover:bg-blue-700 text-xs sm:text-sm'
                                            onClick={() => navigate(`/EditRecipe/${recipe._id}`)}
                                        >
                                            Edit
                                        </button>
                                        <button
                                            className='bg-red-600 text-white hover:scale-105 px-2 sm:px-4 py-1 sm:py-2 rounded hover:bg-red-800 text-xs sm:text-sm'
                                            onClick={(event) => handleDeleteClick(recipe._id, event)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="flex justify-between mt-4 w-full px-6">
                <button 
                    onClick={handlePrevPage} 
                    disabled={currentPage === 1} 
                    className="bg-black text-white px-4 py-2 rounded hover:bg-gray-700"
                >
                    Previous
                </button>
                <button 
                    onClick={handleNextPage} 
                    disabled={currentPage >= Math.ceil(recipes.length / recipesPerPage)} 
                    className="bg-black text-white px-4 py-2 rounded hover:bg-gray-700"
                >
                    Next
                </button>
            </div>
        </section>
    )
}

export default RecipesTable;
