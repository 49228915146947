import React from 'react';
import { useNavigate } from 'react-router-dom';

interface Post {
    _id: string;
    postTitle: string;
    postAuthor: string;
    postDescription: string;
    postDate: string;
    postHashtags: string;
}

interface PostCardForSearchPostPage {
    post: Post;
}

const SearchPostCard: React.FC<PostCardForSearchPostPage> = ({ post }) => {
    const navigate = useNavigate();

    return (
        <section className="w-120 mx-auto  bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md transition-transform transform hover:scale-105 hover:shadow-xl my-2 p-4 sm:p-6">
            <div className="flex flex-col h-full">
                <div className="mb-4">
                    <div className="font-bold text-xl sm:text-2xl mb-2 text-gray-900">{post.postTitle}</div>
                    <div className="flex items-center text-gray-700">
                        <div className="flex-grow">
                            <div><strong>Author:</strong> {post.postAuthor}</div>
                            <div><strong>Hashtags:</strong> {post.postHashtags}</div>
                        </div>
                        <div className="mt-4 sm:mt-0 ml-4 sm:ml-6 text-right">
                            <button
                                onClick={() => navigate(`/postDetails/${post._id}`)}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 sm:px-6 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 transition-colors">
                                View Post
                            </button>
                        </div>
                    </div>
                </div>
                {/* Additional content or footer if needed */}
            </div>
        </section>
    );
};

export default SearchPostCard;
