import React from 'react';
import SignUpForm from './SignUpForm';
import NavbarForLoginAndSignup from '../../components/sidebar/NavbarForLoginAndSignup';


const SignUpPage:React.FC = () => {
    return(
        <div className='flex'>
            <NavbarForLoginAndSignup/>
            <div className='flex-grow'>
                <SignUpForm/>
            </div>
        </div>
    )
}


export default SignUpPage