import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SideNavBar: React.FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isUserSideBarOpen, setIsUserSideBarOpen] = useState(false);
    const [isRecipesSideBarOpen, setIsRecipesSideBarOpen] = useState(false);
    const [isPostSideBarOpen, setIsPostSideBarOpen] = useState(false);
    const navigate = useNavigate();

    // Authentication 
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('token');
            setIsLoggedIn(!!token);
        };
        fetchData();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleUserSideBar = () => {
        setIsUserSideBarOpen(!isUserSideBarOpen);
    };

    const toggleRecipeSideBar = () => {
        setIsRecipesSideBarOpen(!isRecipesSideBarOpen);
    };

    const togglePostSideBar = () => {
        setIsPostSideBarOpen(!isPostSideBarOpen);
    };

    const handleLogout = () => {
        localStorage.clear();
        setIsLoggedIn(false);
        navigate('/');
        window.location.reload();
    };

    return (
        <section>
            <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 text-black">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start w-full space-x-2 md:space-x-2 lg:space-x-0 ">
                            <button
                                onClick={toggleSidebar}
                                aria-controls="logo-sidebar"
                                type="button"
                                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg hover:bg-gray-100 focus:outline-none "
                            >
                                <span className="sr-only">Open sidebar</span>
                                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                                </svg>
                            </button>
                            <a href="/" className="flex items-center">
                                <span className="self-center font-semibold text-sm sm:text-base lg:text-lg whitespace-nowrap max-sm:mr-10 text-black hover:text-spring-green-900 hover:scale-105">
                                    The Global Recipe Book
                                </span>
                            </a>
                        </div>
                        <div className="flex-grow"></div>
                        <ul className='flex space-x-2 sm:space-x-4 md:space-x-6 lg:space-x-10 text-xs sm:text-sm md:text-base lg:text-lg'>
                            <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                <a href='/userSettings'>User</a>
                            </li>
                            <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                <a href='/recipesDashboard'>Recipes</a>
                            </li>
                            <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                <a href='/postsDashboard'>Posts</a>
                            </li>
                            {isLoggedIn ? (
                                <li className='hover:text-spring-green-500 hover:scale-110 transition-transform'>
                                    <a onClick={handleLogout}>Logout</a>
                                </li>
                            ) : (
                                <>
                                    <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                        <a href='/login'>Login</a>
                                    </li>
                                    <li className='hover:text-green-500 hover:scale-110 transition-transform'>
                                        <a href='/signup'>SignUp</a>
                                    </li>
                                </>
                            )}
                        </ul>
                        <div className="flex items-center ms-3">
                            <button
                                type="button"
                                className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                                aria-expanded="false"
                                data-dropdown-toggle="dropdown-user"
                            >
                                <span className="sr-only">Open user menu</span>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <aside
                id="logo-sidebar"
                className={`fixed top-0 left-0 z-40 w-72 h-screen pt-20 transition-transform shadow-xl bg-white ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}
                aria-label="Sidebar"
            >
                <div className="h-full px-3 pb-4 overflow-y-auto bg-white">
                    <ul className="space-y-2 font-medium text-xs sm:text-sm md:text-base lg:text-lg">
                        <li>
                            <a onClick={toggleUserSideBar} className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                </svg>
                                <span className="ms-3 flex-grow font-bold">User</span>
                                {isUserSideBarOpen ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 transition-transform">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 transition-transform">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12.75l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                )}
                            </a>
                        </li>
                        {isUserSideBarOpen && (
                            <ul>
                                {/* <li>
                                    <a href="/userDashboard" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">User Dashboard</span>
                                    </a>
                                </li> */}
                                <li>
                                    <a href="/userSettings" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">User Settings</span>
                                    </a>
                                </li>
                            </ul>
                        )}

                        <li>
                            <a onClick={toggleRecipeSideBar} className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                </svg>
                                <span className="ms-3 flex-grow font-bold">Recipes</span>
                                {isRecipesSideBarOpen ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 transition-transform">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 transition-transform">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12.75l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                )}
                            </a>
                        </li>
                        {isRecipesSideBarOpen && (
                            <ul>
                                <li>
                                    <a href="/recipesDashboard" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">Recipe Dashboard</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/addRecipe" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">Add Recipe</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/searchRecipe" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">Search Recipe</span>
                                    </a>

                                </li>
                            </ul>
                        )}
                        <li>
                            <a onClick={togglePostSideBar} className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                                </svg>
                                <span className="ms-3 flex-grow font-bold">Posts</span>
                                {isPostSideBarOpen ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 transition-transform">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 transition-transform">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12.75l-7.5 7.5-7.5-7.5" />
                                    </svg>
                                )}
                            </a>
                        </li>
                        {isPostSideBarOpen && (
                            <ul>
                                <li>
                                    <a href="/postDashboard" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">Posts Dashboard</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/addPost" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">Create Post</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/searchPost" className="flex items-center p-2 text-black rounded-lg hover:bg-gray-100 group">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                        </svg>
                                        <span className="ms-3 hover:text-spring-green-900 hover:scale-105">Search Post</span>
                                    </a>
                                </li>
                            </ul>
                        )}
                    </ul>
                </div>
            </aside>
        </section>
    );
};

export default SideNavBar;
