import React from 'react';
import SideNavBar from '../../../components/sidebar/SideNavBar';
import SearchBarForPost from './SearchBarForPost';
import SearchApplication from './SearchApplication';





const SearchPostPage: React.FC = () => {
    return (
        <div className='flex'>
            <SideNavBar />
            <div className='mt-24  flex-grow'>
                <SearchApplication />
            </div>
        </div>
    )
}



export default SearchPostPage