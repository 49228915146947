import React from "react";
import SideNavBar from "../../../components/sidebar/SideNavBar";
import EditPostForm from "./EditPostForm";




const EditPostPage:React.FC = () => {
    return (
        <div className="flex">
            <SideNavBar/>
            <EditPostForm/>
        </div>
    )
}


export default EditPostPage