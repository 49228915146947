import React from 'react';
import SideNavBar from '../../../components/sidebar/SideNavBar';
import SearchBarForRecipe from './SearchBarForRecipe';
import SearchRecipeApplication from './SearchApplication';



const SearchRecipePage: React.FC = () => {
    return(
        <div className='flex'>
            <SideNavBar/>
            <div className='mt-24 flex-grow'>
                <SearchRecipeApplication/>
            </div>
        </div>
    )
}


export default SearchRecipePage