import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosSetup';

interface PostComment {
    author: string;
    content: string;
    _id: string;
}

const PostCommentCard: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [postComments, setPostComments] = useState<PostComment[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchComments = async () => {
            try {
                const res = await axiosInstance.get(`postComments/post/${id}`);
                setPostComments(res.data.reverse()); // Reverse the comments array here
            } catch (err) {
                console.error('failed to fetch post comments', err);
            } finally {
                setLoading(false);
            }
        };
        fetchComments();
    }, [id]);

    if (loading) {
        return (
            <div className='flex items-center justify-center min-h-screen bg-gray-100'>
                <div className='text-2xl font-semibold text-gray-600'>Loading...</div>
            </div>
        );
    }

    return (
        <section className='p-6 max-w-4xl mx-auto'>
            {postComments.map((comment) => (
                <div
                    key={comment._id}
                    className='flex flex-col md:flex-row justify-start items-start mb-4 p-4 bg-gray-50 rounded-md shadow-inner border border-gray-200'
                >
                    <div className='w-12 h-12 mr-4'>
                        <img
                            src={`https://ui-avatars.com/api/?name=${comment.author}&background=random&size=48`}
                            alt={comment.author}
                            className='w-full h-full rounded-full object-cover'
                        />
                    </div>
                    <div className='flex flex-col'>
                        <p className='font-semibold text-gray-900'>{comment.author}</p>
                        <p className='text-gray-700 mt-1'>{comment.content}</p>
                    </div>
                </div>
            ))}
        </section>
    );
}

export default PostCommentCard;
